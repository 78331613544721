<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="winnersProvider"
      :fields="tableColumns"
      aria-busy="true"
      class="position-relative"
      responsive
      :tbody-tr-class="rowClass"
      primary-key="id"
      empty-text="No answers found"
      show-empty
    >
      <template #cell(select)="{item}">
        <b-form-checkbox
          @input="(checked)=>{check(item.id,checked)}"
        />
      </template>
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'poll-winners-view', params: { id: item.id} }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50"> View User Profile </span>
          </b-dropdown-item>

        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>

export default {
  name: 'WinnersTable',
  props: {
    winnersProvider: { type: Function, default: () => {} },
  },
  data() {
    return {
      selectedWinnersIDs: [],
      tableColumns: [
        { key: 'select' },
        { key: 'profile', formatter: value => `${value?.first_name} ${value?.last_name}` },
        { key: 'email' },
        { key: 'phone_number' },
        { key: 'correctAnswersCount' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    check(id, checked) {
      if (checked) this.pushId(id)
      else this.removeId(id)
    },
    pushId(id) {
      this.selectedWinnersIDs.push(id)
      this.updateWinnersId()
    },
    removeId(id) {
      const index = this.selectedWinnersIDs.indexOf(id)
      this.selectedWinnersIDs.splice(index, 1)
      this.updateWinnersId()
    },
    updateWinnersId() {
      this.$emit('update:winnersId', this.selectedWinnersIDs)
    },
    rowClass(item, type) {
      const colorClass = 'table-success'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.isWinner) { return colorClass }
    },
  },
}
</script>
<style lang="scss">

</style>
