<template>
  <div>
    <b-row
      align-h="end"
      class="mb-1"
    >
      <b-col md="3">
        <b-button
          variant="primary"
          class="btn-block mb-1 mb-md-0"
          target="_block"
          @click="exportWinners"
        >
          Export Winners
        </b-button>
      </b-col>
      <b-col md="3">
        <b-button
          variant="primary"
          :to="{name:'send-winning-message',params: {usersId:JSON.stringify(winnersId)} }"
          class="btn-block"
          :disabled="winnersId.length===0"
        >
          Send Winning Message
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col md="9">
          <custom-vue-select
            id="right-answers-only"
            placeholder="Answers Type"
            :value-reducer="option=>option.value"
            :options="[
              {text:'All answers',value:false},
              {text:'Right answers only',value:true},
            ]"
            :selected.sync="query.rightAnswersOnly"
            text-field="text"
          />
        </b-col>
        <b-col>
          <search-button @refresh="$refs.parentTable.$refs.table.refresh()" />
        </b-col>
      </b-row>
    </b-card>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <winners-table
      ref="parentTable"
      v-bind="{
        winnersProvider,
      }"
      :winners-id.sync="winnersId"
    />

  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import WinnersTable from '@/common/components/Polls/WinnersTable.vue'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'

export default {
  name: 'WinnersList',
  components: { WinnersTable, CustomVueSelect, SearchButton },
  data() {
    return {
      loading: true,
      winnersId: [],
      query: { rightAnswersOnly: false },
    }
  },
  methods: {
    winnersProvider() {
      return this.$activities.get(`/internalops/live-winners/session/${this.$route.params.liveId}`, {
        params: {
          'only-true': this.query.rightAnswersOnly,
        },
      }).then(res => {
        const winners = res.data.data
        return winners || []
      }).catch(() => []).finally(() => { this.loading = false })
    },
    exportWinners() {
      this.$activities.get(`/internalops/live-winners/session/${this.$route.params.liveId}/export`, {
        responseType: 'blob',
      }).then(res => {
        const blob = new Blob([res.data])
        saveAs(blob, decodeURIComponent(escape(res.headers.filename)))
      })
    },
  },
}
</script>
<style lang="">

</style>
